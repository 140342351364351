(() => {

    /* Todo: Integrate with WebSerialReceiptPrinter, WebUSBReceiptPrinter, etc. */

    let configuration = null;
    let interface = null;

    DeviceManager.Internal.ReceiptPrinter = Object.assign(DeviceManager.Internal.ReceiptPrinter, {

        enabled: function(options) {
            return options.method == 'bluetooth' ||
                   options.method == 'serial' ||
                   options.method == 'usb' || 
                   options.printer == '';
        },

        supports: function(feature) {
            if (feature == 'canAutoPrint') {
                return true;
            }
        },
    
        initialize: async function(args) {
            configuration = args;
            interface = null;

            if (configuration.method === 'bluetooth') {
                interface = DeviceManager.Internal.ReceiptPrinter.Bluetooth;
            }

            if (configuration.method === 'usb') {
                interface = DeviceManager.Internal.ReceiptPrinter.USB;
            }

            if (configuration.method === 'serial') {
                interface = DeviceManager.Internal.ReceiptPrinter.Serial;
            }

            if (interface) {
                await interface.initialize();

                DeviceManager.Internal.ReceiptPrinter.reconnect();
            }
        },

        reconnect: async function() {
            let device = await Runtime.Storage.read({ name: 'receiptPinter' });

            if (device) {
                if (configuration.method === device.method) {
                    await interface.reconnect(device.printer);
                }
            }
        },

        connect: async function() {
            let device = await interface.connect();

            if (device) {
                Runtime.Storage.write('receiptPinter', { method: configuration.method, printer: device });
            }
        },

        disconnect: async function() {
            await interface.disconnect();
        },

        kick: async function(settings, drawer) {
            if (settings.method != interface?.method) {
                await DeviceManager.Internal.ReceiptPrinter.initialize(settings);
            }

            if (!DeviceManager.Internal.ReceiptPrinter.connected) {
                await DeviceManager.Internal.ReceiptPrinter.connect();
            }

            await interface.kick(settings, drawer);
        },

        print: async function(settings, receipt, callback) {
            if (settings.method != interface?.method) {
                await DeviceManager.Internal.ReceiptPrinter.initialize(settings);
            }
            
            if (!DeviceManager.Internal.ReceiptPrinter.connected) {
                await DeviceManager.Internal.ReceiptPrinter.connect();
            }

            await interface.print(settings, receipt);

            if (callback) {
                callback();
            }
        },

        getPrinters: async function() {
            return [];
        },

        getCapabilities: async function() {
            let capabilities = {
                driver:     false,
                bluetooth:  'bluetooth' in navigator,
                serial:     'serial' in navigator,
                usb:        'usb' in navigator && !System.OS.Windows,
                network:    false
            };

            return capabilities;
        }
    });

    Object.defineProperty(DeviceManager.Internal.ReceiptPrinter, 'connected', {
        get() { 
            return interface?.connected; 
        }
    });

    Object.defineProperty(DeviceManager.Internal.ReceiptPrinter, 'name', {
        get() { 
            return interface?.name; 
        }
    });
})();



 /* Bluetooth */

 (() => {

    let WebBluetoothReceiptPrinter;

    let printer;
    let name;
    let connected = false;
    let language;
    let codepageMapping;

    DeviceManager.Internal.ReceiptPrinter.Bluetooth = {

        initialize: async function() {
            WebBluetoothReceiptPrinter = await requireAsync([ 'core/lib/point-of-sale/webbluetooth-receipt-printer.umd' ])
        },

        setup: async function() {
            await new Promise(resolve => {
                if (printer && printer.disconnect) {
                    printer.disconnect();
                }

                printer = new WebBluetoothReceiptPrinter();

                printer.addEventListener('connected', e => {
                    connected = true;
                    name = e.name;
                    codepageMapping = e.codepageMapping;
                    language = e.language;

                    resolve(e);
                });

                printer.addEventListener('disconnected', e => {
                    connected = false;
                });
            });
        },

        connect: async function () {
            let connected = DeviceManager.Internal.ReceiptPrinter.Bluetooth.setup();

            await printer.connect();

            await connected;
        },

        reconnect: async function(device) {
            let connected = DeviceManager.Internal.ReceiptPrinter.Bluetooth.setup();

            await printer.reconnect(device);

            await connected;
        },

        disconnect: function () {
            if (printer && printer.disconnect) {
                printer.disconnect();
            }
        },

        kick: async function(settings, drawer) {
            let configuration = DeviceManager.Internal.ReceiptPrinter.Bluetooth.getConfiguration(settings);    
            let rendered = await DeviceManager.Internal.ReceiptPrinter.Renderer.Native.kick(configuration, drawer);

            printer.print(rendered);
        },

        print: async function(settings, receipt) {
            let configuration = DeviceManager.Internal.ReceiptPrinter.Bluetooth.getConfiguration(settings);    
            let rendered = await DeviceManager.Internal.ReceiptPrinter.Renderer.Native.render(configuration, settings, receipt);
        
            printer.print(rendered);
        },

        getConfiguration: function(settings) {
            let configuration;

            if (settings.model == '') {
                configuration = {
                    language:           settings.language == 'ESC/POS' ? 'esc-pos' : 'star-line',
                    columns:            parseInt(settings.width, 10),
                }
            }
            else if (settings.model == 'auto') {
                configuration = {
                    language,
                    codepageMapping,
                    columns: name.includes('TM-P') || name.includes('SM-L') || name == 'mC-Print2' || name == 'mPOP' ? 32 : 42, 
                }
            }
            else {
                configuration = {
                    printerModel:       settings.model,
                }
            }
    
            return configuration;
        },

        get connected() {
            return connected;
        },
        
        get name() {
            return name;
        },

        get method() {
            return 'bluetooth';
        }
    }

})();


/* USB */

(() => {

    let WebUSBReceiptPrinter;

    let printer;
    let name;
    let connected = false;
    let language;
    let codepageMapping;

    DeviceManager.Internal.ReceiptPrinter.USB = {

        initialize: async function() {
            WebUSBReceiptPrinter = await requireAsync([ 'core/lib/point-of-sale/webusb-receipt-printer.umd' ])
        },

        setup: async function() {
            await new Promise(resolve => {
                if (printer && printer.disconnect) {
                    printer.disconnect();
                }

                printer = new WebUSBReceiptPrinter();

                printer.addEventListener('connected', e => {
                    connected = true;
                    name = e.productName;
                    codepageMapping = e.codepageMapping;
                    language = e.language;

                    resolve(e);
                });

                printer.addEventListener('disconnected', e => {
                    connected = false;
                });
            });
        },

        connect: async function () {
            let connected = DeviceManager.Internal.ReceiptPrinter.USB.setup();

            await printer.connect();

            await connected;
        },

        reconnect: async function(device) {
            let connected = DeviceManager.Internal.ReceiptPrinter.USB.setup();

            await printer.reconnect(device);

            await connected;
        },

        disconnect: function () {
            if (printer && printer.disconnect) {
                printer.disconnect();
            }
        },

        kick: async function(settings, drawer) {
            let configuration = DeviceManager.Internal.ReceiptPrinter.USB.getConfiguration(settings);    
            let rendered = await DeviceManager.Internal.ReceiptPrinter.Renderer.Native.kick(configuration, drawer);

            printer.print(rendered);
        },

        print: async function(settings, receipt) {
            let configuration = DeviceManager.Internal.ReceiptPrinter.USB.getConfiguration(settings);    
            let rendered = await DeviceManager.Internal.ReceiptPrinter.Renderer.Native.render(configuration, settings, receipt);
    
            printer.print(rendered);
        },

        getConfiguration: function(settings) {
            let configuration;

            if (settings.model == '') {
                configuration = {
                    language:           settings.language == 'ESC/POS' ? 'esc-pos' : 'star-line',
                    columns:            parseInt(settings.width, 10),
                }
            }
            else if (settings.model == 'auto') {
                configuration = {
                    language,
                    codepageMapping,
                    columns: name.includes('TM-P') || name.includes('SM-L') || name == 'mC-Print2' || name == 'mPOP' ? 32 : 42, 
                }
            }
            else {
                configuration = {
                    printerModel:       settings.model,
                }
            }
    
            return configuration;
        },

        get connected() {
            return connected;
        },

        get name() {
            return name;
        },

        get method() {
            return 'usb';
        }
    }

})();


/* Serial */

(() => {

    let WebSerialReceiptPrinter;

    let printer;
    let connected = false;

    DeviceManager.Internal.ReceiptPrinter.Serial = {

        initialize: async function() {
            WebSerialReceiptPrinter = await requireAsync([ 'core/lib/point-of-sale/webserial-receipt-printer.umd' ])
        },

        setup: async function() {
            await new Promise(resolve => {
                if (printer && printer.disconnect) {
                    printer.disconnect();
                }

                printer = new WebSerialReceiptPrinter();

                printer.addEventListener('connected', e => {
                    connected = true;
                    resolve(e);
                });

                printer.addEventListener('disconnected', e => {
                    connected = false;
                });
            })
        },

        connect: async function () {
            let connected = DeviceManager.Internal.ReceiptPrinter.Serial.setup();
            
            await printer.connect();

            await connected;
        },

        reconnect: async function(device) {
            let connected = DeviceManager.Internal.ReceiptPrinter.Serial.setup();
            
            await printer.reconnect(device);

            await connected;
        },
        
        disconnect: function () {
            if (printer && printer.disconnect) {
                printer.disconnect();
            }
        },

        kick: async function(settings, drawer) {
            let configuration = DeviceManager.Internal.ReceiptPrinter.Serial.getConfiguration(settings);    
            let rendered = await DeviceManager.Internal.ReceiptPrinter.Renderer.Native.kick(configuration, drawer);

            printer.print(rendered);
        },

        print: async function(settings, receipt) {
            let configuration = DeviceManager.Internal.ReceiptPrinter.Serial.getConfiguration(settings);    
            let rendered = await DeviceManager.Internal.ReceiptPrinter.Renderer.Native.render(configuration, settings, receipt);
    
            printer.print(rendered);
        },

        getConfiguration: function(settings) {
            let configuration;

            if (settings.model == '') {
                configuration = {
                    language:           settings.language == 'ESC/POS' ? 'esc-pos' : 'star-line',
                    columns:            parseInt(settings.width, 10),
                }
            }
            else {
                configuration = {
                    printerModel:       settings.model,
                }
            }
    
            return configuration;
        },

        get connected() {
            return connected;
        },

        get name() {
            return 'Naamloos apparaat';
        },

        get method() {
            return 'serial';
        }
    }

})();