Runtime.View = class RuntimeView {

    constructor(controller, options) {
        this.controller = controller;
        this.options = options;
    
        this.header = document.querySelector('ui-header');

        this._internal = {
            tabs:       [],
            actions:    [],
            rendered:   false
        }
    }

    render(data) {
        this._internal.rendered = true;

        let actions = this.header.querySelector('ui-actions');

        if (data.lock) {
        	var lock = new Element('button', { 'id': 'lock', 'class': 'expert' });
            lock.update(data.lock.locked ? "\uE91D" : "\uE91E");
        	actions.appendChild(lock);
            
            let code = data.lock.code;
            lock.addEventListener('click', () => {
                if (!document.body.classList.contains('overlay')) {
                    this.options.onToggleLock(code);
                }
            });
        }

        Devices.initialize(actions, this.controller);
        Print.initialize(actions, this.controller, this._internal.actions);
        Export.initialize(actions, this.controller, this._internal.actions);
    }

    update(data, response) {
        if (!this._internal.rendered) {
            this.render(data);
        }

        let current = this.header.querySelector('#current');

        if (this.controller.main.loader.data) {
			var actors = this.controller.getActors();
			for (var i = 0; i < actors.length; i++) {
				if (actors[i].stage == this.controller.main.loader.data.stage && actors[i].actor == this.controller.main.loader.data.actor) {
					current.update(escapeHTML(actors[i].fullname || actors[i].name))
				}
			}
		}

        let navigation = this.header.querySelector('ui-navigation');

        while (navigation.firstChild) {
            navigation.removeChild(navigation.firstChild);
        }

		if (data.tabs.items.length) {
            this._internal.tabs = [];

            for (var i = 0; i < data.tabs.items.length; i++) {
                let item = data.tabs.items[i];

                let element = new Element('button', { 'class': 'item' });
                element.innerHTML = (item.icon ? "<span class='icon'>" + item.icon + "</span> " : "") + "<span class='label'>" + item.name + "</span>";
                element.addEventListener('click', e => {
                    if (!document.body.classList.contains('overlay')) {
                        navigation.querySelectorAll('.selected').forEach(e => e.classList.remove('selected'));
                        element.classList.add('selected');
                        response(item.id);
                    }
                });

                if (!item.enabled) {
                    element.disabled = true;
                }

                if (item.id == data.tabs.current) {
                    element.classList.add('selected');
                }

                this._internal.tabs[item.id] = element;
                                
                navigation.appendChild(element);
            }

            if (data.tabs.current) {
                this.options.onNavigate(data.tabs.current);
            }    
        }
    }

    clear() {
        this.header.querySelectorAll('ui-navigation *').forEach(i => i.remove());
        this.header.querySelectorAll('ui-actions :not(#about)').forEach(i => i.remove());
        this.header.querySelector('#current').innerHTML = '';

        this._internal.rendered = false;
    }

    navigate(id) {
        let element = this._internal.tabs[id];
        let navigation = this.header.querySelector('ui-navigation');

		if (element) {
			[...navigation.children].forEach(i => i.classList.remove('selected'));
			element.classList.add('selected');
		}

        this.options.onNavigate(id);
    }

    /* Actions */

    get actions() {
        return this._internal.actions;
    }

    set actions(value) {
        this._internal.actions = value;
        
        Print.update(value);
		Export.update(value);

        document.fire('application:restore');
    }

    /* Lock */

    set locked(value) {
        let lock = this.header.querySelector('#lock');

        if (value) {
            lock.update("\uE91D");
            lock.classList.remove('unlocked');    
        } else {
            lock.update("\uE91E");
            lock.classList.add('unlocked');
        }
    }
}