CSS.insert(`
	.widget.panel.intro { text-align: right; }
	.widget.panel.intro img { margin-bottom: 16px; }
	.widget.panel.intro p { margin-bottom: 16px; line-height: 140%; font-size: 0.9em; }
	.widget.panel.intro p + p { font-size: 0.7em; }
	.Mobile .widget.panel.intro { text-align: center; }
	.Mobile.Tablet .widget.panel.intro { text-align: right; }
	.layout.panels > div > .panel:first-child + .panel { background: url(${_ROOT}images/loader-regular.gif) center center no-repeat; background-size: 24px 24px; }
	@media (min-resolution: 2dppx), (-webkit-min-device-pixel-ratio: 2) { .layout.panels > div > .panel:first-child + .panel { background-image: url(${_ROOT}images/loader-regular@2x.gif); } }
	.widget.panel.status { font-size: 0.75em; position: relative; text-align: center; color: #666; line-height: 150%; }
	.widget.panel.error { font-size: 0.75em; font-weight: var(--font-weight-bold); position: relative; text-align: center; line-height: 150%; }
	.widget.panel.error span.disconnected { position: relative; top: -20px; font-family: SalonIcon; font-size: 100pt; color: #ddd; line-height: 100%; }
	.widget.panel.error span.disconnected span { position: absolute; top: 50px; left: 70px; font-size: 40pt; color: #900; }
	.widget.panel.warning, .widget.panel.notice { font-size: 0.75em; padding-left: 40px; position: relative; }
	.widget.panel.warning ul, .widget.panel.notice ul { margin: 0.8em 0 0.8em 1.3em; }
	.widget.panel.warning::before { display: block; content: '!'; font-family: SalonIcon; color: var(--colors-red); font-size: 32px; position: absolute; left: 0;}
	.widget.panel.notice::before { display: block; content: '!'; font-family: SalonIcon; color: var(--colors-named-accent); font-size: 32px; position: absolute; left: 0;}

	.widget.loginPanel h3 {
		color: #444;
		margin-bottom: 0.8em;
		text-transform: var(--font-transform);
	}

	.widget.downloadPanel h3 {
		color: #444;
		margin-bottom: 0.6em;
		text-transform: var(--font-transform);
	}
	.widget.downloadPanel p {
		font-size: 0.75em;
		margin-top: 0;
		margin-bottom: 1em;
	}
`);

Runtime.Login = Class.create({
	initialize: function(controller) {
		this.controller = controller;

		this.valid = false;
		this.enabled = true;
		this.incognito = false;
		

		/* Valid browsers are current version and the previous two */

		if (System.Type.Desktop) {
			if (UserAgent.Edge('>=', 100)) this.valid = true;
			else if (UserAgent.Chrome('>=', 100)) this.valid = true;
			else if (UserAgent.Firefox('>=', 91)) this.valid = true;
			else if (UserAgent.Safari('>=', 14)) this.valid = true;
		}
		
		if (System.Type.Tablet) {
			if (UserAgent.Safari('>=', 14)) this.valid = true;
			else if (UserAgent.Chrome('>=', 100)) this.valid = true;
		}


		/* Try to detect incogito mode... try, because it will mostly not work */

		try {
			window.localStorage.setItem('incognito', 'false');
		}
		catch(e) {
			this.incognito = true;
			this.enabled = false;
		}

		this.wrapper = document.querySelector('ui-login');
		this.wrapper.update('');

		this.page = new Page.Login(this.wrapper);

		this.panels = new Layout.Panels(this.page.content, [
			{ name: 'login' }, { name: 'salons' }, { name: 'errors' }
		]);



		var loginParent, logoParent;

		if (System.Type.Mobile && !System.Type.Tablet) {
			var horizontal = new Layout.Flex(this.panels.items.login, {
				pack: 'center',
				items: [
					{ name: 'center', 'width': '285px' }
				]
			});

			var vertical = new Layout.Flex(horizontal.items.center, {
				orientation: 'vertical',
				pack: 'center',
				items: [
					{ name: 'top', 'height': '40px' },
					{ name: 'logo', 'height': '140px' },
					{ name: 'login', 'height': '500px' },
				]
			});

			loginParent = vertical.items.login;
			logoParent = vertical.items.logo
		}
		
		else {
			var vertical = new Layout.Flex(this.panels.items.login, {
				orientation: 'vertical',
				pack: 'center',
				items: [
					{ name: 'top', 'height': '40px' },
					{ name: 'center', 'height': '500px' },
				]
			});

			var horizontal = new Layout.Flex(vertical.items.center, {
				pack: 'center',
				items: [
					{ name: 'center', 'width': '600px' }
				]
			});

			var columns = new Layout.Flex(horizontal.items.center, {
				items: [
					{ name: 'left', 'width': '285px' },
					{ name: 'center', 'width': '30px' },
					{ name: 'right', 'width': '285px' },
				]
			});

			loginParent = columns.items.right;
			logoParent = columns.items.left
		}

		

		/* Logo */

		var panel = new Widgets.Panel(logoParent, {
			style: 		'none',
			className:	'intro'
		});

		panel.update(
			"<img src='../../../assets/images/logo.svg' width='180' height='33' alt='Salonhub'>" +
			"<p>De professionele online<br> oplossing voor kapsalons</p>"
		)


		/* loginPanel */

		this.loginPanel = new Widgets.Panel(loginParent, {
			style: 			'blank',
			className:		'loginPanel',
			template:		html`
				<h3>
					Direct inloggen
				</h3>
			`
		});

		this.clientInput = new Widgets.Field(this.loginPanel, {
			name:			'client',
			placeholder:	'Klant',
			autoCapitalize:	false,
			autoCorrect:	false,
			lowercase:		true,
			allow:			'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890',
			value:			this.controller.getClient(),
			onChange: 		this.onCheck.bind(this),
			onEnter:		function () { this.nameInput.focus() }.bind(this)
		});

		this.nameInput = new Widgets.Field(this.loginPanel, {
			name:			'user',
			autoComplete:	'username',
			autoCapitalize:	false,
			autoCorrect:	false,
			lowercase:		true,
			allow:			'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890@.',
			placeholder:	'Gebruikersnaam',
			onChange: 		this.onCheck.bind(this),
			onEnter:		function () { this.passwordInput.focus() }.bind(this)
		});

		this.passwordInput = new Widgets.Field(this.loginPanel, {
			name:			'password',
			type:			'password',
			placeholder:	'Wachtwoord',
			onChange: 		this.onCheck.bind(this),
			onEnter:		function() { window.setTimeout(function() { if (this.clientInput.value.trim() != '' && this.nameInput.value.trim() != '' && this.passwordInput.value.trim() != '') this.onLogin(); }.bind(this), 0) }.bind(this)
		});

		if (this.incognito) {
			new Widgets.Separator(this.loginPanel, {
				width: 			'page'
			});
			
			var message = new Widgets.Panel(this.loginPanel, {
				style: 		'none',
				className:	'warning'
			});

			message.update(
				"<p>Staat <em>Privé mode</em> of <em>Incognito mode</em> aan in uw browser? Deze moet uit staan voordat u kunt inloggen.</p>"
			)
		}

		if (!this.valid) {
			new Widgets.Separator(this.loginPanel, {
				width: 			'page'
			});
			
			var message = new Widgets.Panel(this.loginPanel, {
				style: 		'none',
				className:	this.enabled ? 'notice' : 'warning'
			});

			if (this.enabled) {
				message.update(`
					<p>
						Uw browser wordt niet ondersteund door Salonhub. We raden aan om de laatste versie van Edge, Firefox, Chrome of Safari te gebruiken op een desktop computer, laptop of tablet.
					</p>
				`);
			}
			else {
				message.update(`
					<p>
						Om te kunnen inloggen heeft u een nieuwere versie van Edge, Firefox, Chrome of Safari nodig op een desktop computer, laptop of tablet.
					</p>
				`);
			}
		}
		
		new Widgets.Separator(this.loginPanel, {
			width: 			'page'
		});

		this.saveButton = new Widgets.Button(this.loginPanel, {
			color:			'green',
			title:			'Inloggen',
			onClick:		this.onLogin.bind(this)
		});

		this.onCheck();


		/* Download panel */

		let platform = null;

		if (System.OS.Mac) {
			platform = 'macOS';
		}

		if (System.OS.Windows) {
			platform = 'Windows';
		}

		if (System.OS.Chromebook) {
			platform = 'Chromebook';
		}

		if (System.OS.iOS && System.Type.Tablet) {
			platform = 'iPad';
		}

		if (System.OS.Android && System.Type.Tablet) {
			platform = 'Android';
		}

		if (System.Features.ProgressiveWebApp || System.Features.TrustedWebActivity) {
			platform = null;
		}

		if (platform) {
			this.downloadPanel = new Widgets.Panel(loginParent, {
				style: 			'blank',
				className:		'downloadPanel',
				template:		html`
					<h3>
						Download Salonhub
					</h3>
					<p>
						Voor meer mogelijkheden download en installeer Salonhub voor ${platform}.
					</p>
				`
			});

			this.downloadButton = new Widgets.Button(this.downloadPanel, {
				color:			'green',
				title:			'Download',
				onClick:		() => location.href = 'https://www.salonhub.support/download/'
			});
		}
		
		this.wrapper.removeAttribute('hidden');
	},

	close: function() {
		this.wrapper.setAttribute('hidden', '');
		this.wrapper.update('');
	},

	onCheck: function() {
		var enabled = true;

		if (this.clientInput.value.trim() == '') {
			enabled = false;
		}

		if (this.nameInput.value.trim() == '') {
			enabled = false;
		}

		if (this.passwordInput.value.trim() == '') {
			enabled = false;
		}

		this.saveButton.enabled = enabled && this.enabled;
	},

	onLogin: async function() {
		this.loginPanel.classList.remove('animated', 'bounceInRight');

		let credentials = `client=${this.clientInput.value.trim()}&user=${this.nameInput.value.trim()}&digest=${MD5(this.passwordInput.value.trim())}`;
		let url = `api/=${base32.stringify(credentials)}/Session.User/login`;

		new Ajax.Request(Settings.api + url, {
			method: 	'post',
			parameters: {
				version:	Settings.version,
				guid:		this.controller.settings.guid,
				details: 	JSON.stringify(await Runtime.System.get()) 
			},

			onSuccess: 	function(transport) {
				if (transport.status == 0) {
					this.controller.account.client = this.clientInput.value.trim();

					var login, permissions;

					try {
						login = JSON.parse(window.localStorage.getItem(this.controller.prefix() + 'login'));
						permissions = JSON.parse(window.localStorage.getItem(this.controller.prefix() + 'permissions'));
					}
					catch(e) {
					}

					if (login && permissions) {
						if (login.username == this.nameInput.value.trim() && login.password == MD5(this.passwordInput.value.trim())) {
							this.controller.account.login = login;
							this.controller.account.permissions = permissions;
		
							this.panels.forward()
							this.controller.update();
		
							setTimeout(() => {
								Fx.fade(this.wrapper, {
									duration: 0.5,
									after: () => { 
										Runtime.resetViewport();
										this.close(); 
									}
								});
							}, 500);
						}
					}

					this.loginPanel.classList.add('animated', 'wobble');
					setTimeout(() => this.loginPanel.classList.remove('animated', 'wobble'), 1500);
					
					this.nameInput.value = '';
					this.passwordInput.value = '';
					this.saveButton.enabled = false;
				}

				var response = JSON.parse(transport.responseText);
				if (response) {
					this.controller.api = response.api;
					this.controller.account.client = this.clientInput.value.trim();
					this.controller.account.login = { username: this.nameInput.value.trim(), password: MD5(this.passwordInput.value.trim()) };
					this.controller.tokens = response.tokens;
					this.controller.account.permissions = response.permissions;

					window.localStorage.setItem('account', this.controller.account.client);
					window.localStorage.setItem(this.controller.prefix() + 'login', JSON.stringify(this.controller.account.login));
					window.localStorage.setItem(this.controller.prefix() + 'permissions', JSON.stringify(this.controller.account.permissions));

					if (response.tokens['Session.*']) {
						window.localStorage.setItem(this.controller.prefix() + 'token', response.tokens['Session.*']);
					}

					this.panels.forward()
					this.controller.update();

					setTimeout(() => {
						Fx.fade(this.wrapper, {
							duration: 	0.5,
							after: 	  	() => { 
								Runtime.resetViewport();
								this.close(); 
							}
						});
					}, 500);
				} else {
					this.loginPanel.classList.add('animated', 'wobble');
					setTimeout(() => this.loginPanel.classList.remove('animated', 'wobble'), 1500);
	
					this.nameInput.value = '';
					this.passwordInput.value = '';
					this.saveButton.enabled = false;
				}
			}.bind(this),

			onFailure:	function(transport) {
				this.loginPanel.classList.add('animated', 'wobble');
				setTimeout(() => this.loginPanel.classList.remove('animated', 'wobble'), 1500);

				this.nameInput.value = '';
				this.passwordInput.value = '';
				this.saveButton.enabled = false;
			}.bind(this)
		});
	}
});
